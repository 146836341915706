import styles from "../styles/components/product.module.scss";
import Select from "./select";
import {currencyFormatter} from "../lib/formatter.js";

function Product({ uuid, name, image, price, selected, updateCart, ...props }) {
  return (
    <div
      className={`${styles.Product} ${selected ? styles.selected : ""}`}
      {...props}
    >
      <div>
        <img src={image} alt={name} />
      </div>
      <div className={styles.data}>
        <h3 className={styles.title}>{name}</h3>
        <div>
          <span className={styles.price}>{currencyFormatter(price,"EUR")}</span>
          <Select
            uuid={uuid}
            options={new Array(10).fill("").map((d, i) => i)}
            check={1}
            onAction={(e) =>
              updateCart([{ uuid: uuid, quantity: parseInt(e.target.value) }])
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Product;
