import styles from "../styles/components/option.module.scss";

function Option({ title, image, link, selected, onAction, ...props }) {
  return (
    <div
      className={`${styles.Option} ${selected ? styles.selected : ""}`}
      {...props}
      onClick={() => {
        link && onAction(link);
      }}
    >
      <div>
        <img src={image} alt={title} />
      </div>
      <h3 className={styles.title}>{title}</h3>
    </div>
  );
}

export default Option;
