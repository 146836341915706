import { useState } from "react";
import styles from "../styles/components/collapsableNav.module.scss";
function CollapsableNav({ title, children }) {
  const [active, setActive] = useState(false);
  return (
    <div className={`${styles.CollapsableNav} ${active ? styles.active : ""}`}>
      <p onClick={() => setActive((active) => !active)}>{title}</p>
      {children}
    </div>
  );
}

export default CollapsableNav;
