import styles from "../styles/components/breadcrumbs.module.scss";

function Breadcrumbs({ entries, restrict }) {
  return (
    <div className={styles.Breadcrumbs}>
      <span>Your Selections: </span>
      {restrict === 0 ? (
        <span>Entry point</span>
      ) : (
        <ul>
          {entries
            .filter((d, i) => i < restrict)
            .map((d) => (
              <li key={`${d.uuid}_crumb`}>{d.title}</li>
            ))}
        </ul>
      )}
    </div>
  );
}

export default Breadcrumbs;
