import styles from "../styles/components/select.module.scss";
function Select({ uuid, options, check, onAction }) {
  return (
    <div className={styles.Select}>
      <select onChange={onAction} defaultValue={check}>
        {options.map((d, i) => (
          <option key={`${uuid}_select_${i}`}>{d}</option>
        ))}
      </select>
    </div>
  );
}

export default Select;
