import { useState, useRef, useEffect } from "react";

import styles from "../styles/components/scene.module.scss";
import Button from "./button";
import Option from "./option";
import Product from "./product";
import Checkout from "./checkout";
import Breadcrumbs from "./breadcrumbs";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function Scene({
  uuid,
  title,
  type,
  options,
  settings,
  cart = [],
  onAction,
  updateCart,
  purchase,
  ...props
}) {
  const [breadcrumbs, setBreadcrumbs] = useState({ entries: [], restrict: -1 });
  const [navigation, optionList] = options.reduce(
    (a, c) =>
      c.uuid.startsWith("_")
        ? !c.link
          ? a
          : [[...a[0], c], a[1]]
        : [a[0], [...a[1], c]],
    [[], []]
  );
  const prevOptions = usePrevious(options);
  useEffect(() => {
    setBreadcrumbs((breadcrumbs) => ({
      restrict: breadcrumbs.restrict + 1,
      entries: [
        ...breadcrumbs.entries,
        ...((prevOptions &&
          prevOptions.filter((d) =>
            d.selected
              ? d
              : d.uuid === props.breadcrumb && !props.breadcrumb.startsWith("_")
              ? d
              : false
          )) ||
          []),
      ],
    }));
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [uuid]);
  return (
    <div className={styles.Scene}>
      <Breadcrumbs {...breadcrumbs} />
      <h2 className={styles.title}>{title}</h2>
      {type === "exit" ? (
        <Checkout
          cart={cart}
          updateCart={updateCart}
          purchase={purchase}
          {...props}
        />
      ) : settings.mode !== "catalog" ? (
        <div className={styles.options}>
          {optionList.map((d) => (
            <Option key={d.uuid} {...d} onAction={onAction} />
          ))}
        </div>
      ) : (
        <div className={styles.products}>
          {settings.products.map((d) => (
            <Product
              key={d}
              uuid={d}
              {...cart.find((e) => e.uuid === d)}
              updateCart={updateCart}
            />
          ))}
        </div>
      )}
      {type !== "exit" && (
        <div className={styles.navigation}>
          {navigation.map((d) => (
            <Button key={d.uuid} {...d} onAction={onAction}>
              {d.title}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
}

export default Scene;
