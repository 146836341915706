import styles from "../styles/components/alert.module.scss";
function Alert({ children, onAction }) {
  return (
    <div className={styles.Alert} onClick={onAction}>
      <div className={styles.container}>{children}</div>
    </div>
  );
}

export default Alert;
