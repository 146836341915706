import { useEffect, useState } from "react";
import Alert from "./components/alert";
import Layout from "./components/layout";
import Scene from "./components/scene";
import Thankyou from "./components/thankyou";
import "./styles/global.scss";

function App() {
  const [scene, setScene] = useState();
  const [cart, setCart] = useState([]);
  const [thankyou, setThankyou] = useState(false);
  const [alert, setAlert] = useState(false);
  function getNextScene(link = "") {
    if (link === "") {
      setThankyou(false);
      setCart([]);
    }
    fetch(`${process.env.REACT_APP_API}blocks/next/${link}`, {
      credentials: "include",
    })
      .then((d) => {
        if (!d.ok) throw new Error(d.status);
        return d.json();
      })
      .then((d) => setScene(d.data.scene))
      .catch((err) => {
        setAlert("Something went wrong, please try again later!");
      });
  }
  function updateCart(data = []) {
    fetch(`${process.env.REACT_APP_API}booking/cart`, {
      credentials: "include",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((d) => {
        if (!d.ok) throw new Error(d.status);
        return d.json();
      })
      .then((d) => setCart(d.data.cart))
      .catch((err) => {
        setAlert("Something went wrong, please try again later!");
      });
  }
  function purchase(data = []) {
    fetch(`${process.env.REACT_APP_API}booking/checkout`, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((d) => {
        if (!d.ok) throw new Error(d.status);
        return d.json();
      })
      .then((d) => setThankyou(true))
      .catch((err) => {
        setAlert("Something went wrong, please try again later!");
      });
  }
  useEffect(() => {
    getNextScene();
  }, []);
  useEffect(() => {
    if (scene && scene.settings.mode === "catalog") {
      updateCart([
        ...scene.settings.products.map((d) => ({ uuid: d, quantity: 1 })),
      ]);
    }
  }, [scene]);
  return (
    <Layout>
      {thankyou ? (
        <Thankyou onAction={getNextScene} />
      ) : (
        scene && (
          <Scene
            {...scene}
            cart={cart}
            onAction={getNextScene}
            updateCart={updateCart}
            purchase={purchase}
          ></Scene>
        )
      )}
      {alert && <Alert onAction={() => setAlert(false)}>{alert}</Alert>}
    </Layout>
  );
}

export default App;
