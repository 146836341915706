import styles from "../styles/components/layout.module.scss";
import Header from "./header";
import Footer from "./footer";

function Layout(props) {
  return (
    <div className={styles.Layout}>
      <div className={styles.wrapper}>
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
