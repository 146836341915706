import styles from "../styles/components/footer.module.scss";
import CollapsableNav from "./collapsableNav";

function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.menu}>
        <CollapsableNav title={"Top Marken"}>
          <nav>
            <ul>
              <li>Alle Marken</li>
              <li>Sonos</li>
              <li>Google</li>
              <li>Bosch</li>
              <li>Gardena</li>
              <li>tado°</li>
              <li>Philips Hue</li>
              <li>Fitbit</li>
              <li>Eve</li>
              <li>Ring</li>
            </ul>
          </nav>
        </CollapsableNav>
        <CollapsableNav title={"Top Produkte"}>
          <nav>
            <ul>
              <li>Google Nest Audio</li>
              <li>Sonos ONE</li>
              <li>Sonos Five</li>
              <li>Netatmo Presence</li>
              <li>Eve Thermo</li>
            </ul>
          </nav>
        </CollapsableNav>
        <CollapsableNav title={"Kundenservice"}>
          <nav>
            <ul>
              <li>kontakt</li>
              <li>FAQs</li>
              <li>Versand & Zahlung</li>
              <li>Spotify Aktion</li>
            </ul>
          </nav>
        </CollapsableNav>
        <CollapsableNav title={"tink"}>
          <nav>
            <ul>
              <li> Über uns</li>
              <li>Presse</li>
              <li> tink Blog</li>
              <li>Career</li>
              <li>Partnerprogramm</li>
              <li>AGB</li>
              <li>Datenschutz</li>
              <li>Impressum</li>
              <li>Glossar</li>
              <li>Dateneinstellungen</li>
            </ul>
          </nav>
        </CollapsableNav>
      </div>
      <p>
        Alle Preise inkl. der gesetzl. MwSt. Die durchgestrichenen Preise
        entsprechen der unverbindlichen Preisempfehlung des Herstellers.
      </p>
    </div>
  );
}
export default Footer;
