import styles from "../styles/components/thankyou.module.scss";
import Button from "./button";
function Thankyou({ onAction }) {
  return (
    <div className={styles.Thankyou}>
      <h2>Thanks for your purchase!</h2>
      <div>
        <Button onAction={onAction} link={""}>
          Restart the Configurator!
        </Button>
      </div>
    </div>
  );
}
export default Thankyou;
