import Select from "./select";
import styles from "../styles/components/checkout.module.scss";
import Button from "./button";
import {currencyFormatter} from "../lib/formatter.js";
const Row = ({ children }) => <div>{children}</div>;
const Cell = ({ children }) => <div>{children}</div>;
function Checkout({ cart, updateCart, purchase }) {
  return (
    <div className={styles.Checkout}>
      <div className={styles.table}>
        {cart
          .sort((a, b) => (a.uuid > b.uuid ? 1 : -1))
          .map((d) => (
            <Row key={`${d.uuid}_row`}>
              <Cell>
                <img src={d.image} alt={d.name} />
              </Cell>
              <Cell>{d.name}</Cell>
              <Cell>
                <Select
                  uuid={d.uuid}
                  options={new Array(10).fill("").map((d, i) => i)}
                  check={d.quantity}
                  onAction={(e) =>
                    updateCart([
                      { uuid: d.uuid, quantity: parseInt(e.target.value) },
                    ])
                  }
                />
              </Cell>
              <Cell>{currencyFormatter(d.price,"EUR")}</Cell>
            </Row>
          ))}
        <p className={styles.total}>
          Total:{" "}
          {currencyFormatter(Math.round(
            cart.reduce((a, c) => a + c.quantity * c.price, 0) * 100
          ) / 100,"EUR")}
        </p>
      </div>
      <div className={styles.navigation}>
        <Button link={{ line_items: cart }} onAction={purchase}>
          Purchase
        </Button>
      </div>
    </div>
  );
}
export default Checkout;
